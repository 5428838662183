@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-pt0 {
  padding-top: 0px; }

.u-pb0 {
  padding-bottom: 0px; }

.u-mt0 {
  margin-top: 0px; }

.u-mb0 {
  margin-bottom: 0px; }

.u-pt5 {
  padding-top: 5px; }

.u-pb5 {
  padding-bottom: 5px; }

.u-mt5 {
  margin-top: 5px; }

.u-mb5 {
  margin-bottom: 5px; }

.u-pt10 {
  padding-top: 10px; }

.u-pb10 {
  padding-bottom: 10px; }

.u-mt10 {
  margin-top: 10px; }

.u-mb10 {
  margin-bottom: 10px; }

.u-pt15 {
  padding-top: 15px; }

.u-pb15 {
  padding-bottom: 15px; }

.u-mt15 {
  margin-top: 15px; }

.u-mb15 {
  margin-bottom: 15px; }

.u-pt20 {
  padding-top: 20px; }

.u-pb20 {
  padding-bottom: 20px; }

.u-mt20 {
  margin-top: 20px; }

.u-mb20 {
  margin-bottom: 20px; }

.u-pt25 {
  padding-top: 25px; }

.u-pb25 {
  padding-bottom: 25px; }

.u-mt25 {
  margin-top: 25px; }

.u-mb25 {
  margin-bottom: 25px; }

.u-pt30 {
  padding-top: 30px; }

.u-pb30 {
  padding-bottom: 30px; }

.u-mt30 {
  margin-top: 30px; }

.u-mb30 {
  margin-bottom: 30px; }

.u-pt35 {
  padding-top: 35px; }

.u-pb35 {
  padding-bottom: 35px; }

.u-mt35 {
  margin-top: 35px; }

.u-mb35 {
  margin-bottom: 35px; }

.u-pt40 {
  padding-top: 40px; }

.u-pb40 {
  padding-bottom: 40px; }

.u-mt40 {
  margin-top: 40px; }

.u-mb40 {
  margin-bottom: 40px; }

.u-pt45 {
  padding-top: 45px; }

.u-pb45 {
  padding-bottom: 45px; }

.u-mt45 {
  margin-top: 45px; }

.u-mb45 {
  margin-bottom: 45px; }

.u-pt50 {
  padding-top: 50px; }

.u-pb50 {
  padding-bottom: 50px; }

.u-mt50 {
  margin-top: 50px; }

.u-mb50 {
  margin-bottom: 50px; }

.u-pt55 {
  padding-top: 55px; }

.u-pb55 {
  padding-bottom: 55px; }

.u-mt55 {
  margin-top: 55px; }

.u-mb55 {
  margin-bottom: 55px; }

.u-pt60 {
  padding-top: 60px; }

.u-pb60 {
  padding-bottom: 60px; }

.u-mt60 {
  margin-top: 60px; }

.u-mb60 {
  margin-bottom: 60px; }

.u-pt65 {
  padding-top: 65px; }

.u-pb65 {
  padding-bottom: 65px; }

.u-mt65 {
  margin-top: 65px; }

.u-mb65 {
  margin-bottom: 65px; }

.u-pt70 {
  padding-top: 70px; }

.u-pb70 {
  padding-bottom: 70px; }

.u-mt70 {
  margin-top: 70px; }

.u-mb70 {
  margin-bottom: 70px; }

.u-pt75 {
  padding-top: 75px; }

.u-pb75 {
  padding-bottom: 75px; }

.u-mt75 {
  margin-top: 75px; }

.u-mb75 {
  margin-bottom: 75px; }

.u-pt80 {
  padding-top: 80px; }

.u-pb80 {
  padding-bottom: 80px; }

.u-mt80 {
  margin-top: 80px; }

.u-mb80 {
  margin-bottom: 80px; }

.u-pt85 {
  padding-top: 85px; }

.u-pb85 {
  padding-bottom: 85px; }

.u-mt85 {
  margin-top: 85px; }

.u-mb85 {
  margin-bottom: 85px; }

.u-pt90 {
  padding-top: 90px; }

.u-pb90 {
  padding-bottom: 90px; }

.u-mt90 {
  margin-top: 90px; }

.u-mb90 {
  margin-bottom: 90px; }

.u-pt95 {
  padding-top: 95px; }

.u-pb95 {
  padding-bottom: 95px; }

.u-mt95 {
  margin-top: 95px; }

.u-mb95 {
  margin-bottom: 95px; }

.u-pt100 {
  padding-top: 100px; }

.u-pb100 {
  padding-bottom: 100px; }

.u-mt100 {
  margin-top: 100px; }

.u-mb100 {
  margin-bottom: 100px; }

.c-topicspath {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 568px) {
    .c-topicspath {
      bottom: 15px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 14px;
    color: #FFFFFF; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    @media screen and (max-width: 568px) {
      .c-topicspath li {
        font-size: 10px; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px;
      margin-right: 5px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li > a {
      color: inherit; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 349px;
  background-image: url(http://placeholder.pics/svg/1200x300/FF6B6B);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden; }
  @media screen and (max-width: 568px) {
    .l-lowerCaption {
      height: 300px; } }
  .l-lowerCaption:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #098DB0;
    opacity: .6; }
  #company .l-lowerCaption {
    background-image: url(http://placeholder.pics/svg/1200x300/30FF64); }
  .l-lowerCaption__inner {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    max-width: 1100px;
    width: 100%; }
    @media screen and (max-width: 1130px) {
      .l-lowerCaption__inner {
        width: 100%;
        padding: 0 20px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__inner {
        bottom: 15px;
        left: 0;
        transform: none; } }
  .l-lowerCaption__title {
    color: #fff;
    font-size: 36px;
    width: 100%;
    margin-bottom: 35px; }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__title {
        font-size: 22px;
        margin-bottom: 15px; } }
